// extracted by mini-css-extract-plugin
export var link = "link___fA1hN";
export var action = "action___bSmKU";
export var compact = "compact___mqtRl";
export var negativeVerticalMargin = "negativeVerticalMargin___x66E_";
export var shiftLeft = "shiftLeft___aA2LV";
export var shiftRight = "shiftRight____gIIq";
export var selected = "selected___MGG1L";
export var disabled = "disabled___qYNpQ";
export var svg = "svg___mra4M";
export var primary = "primary___NZySp";
export var rankFont = "rankFont___HjY2D";