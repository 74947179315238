import { combineReducers } from 'redux';
import listDux from './lib/listDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'ratesV3',
  url: '/api/rates/v3/rateInventory/filteredRates',
  switchRequests: false,
  setListPayload : ({ items = [], count = 0, error = undefined, errorMessage = '', query = {}, scope = JSON.stringify(query), ...payload } = {}) => ({ ...payload, [scope]: { items, count, error, errorMessage, isFetching: false, ...payload}, items, count, error, errorMessage, isFetching: false,}),
});

const { reducer: $historicalAverageRates, actions: historicalAverageRates, epics: historicalAverageRatesEpics } = listDux({
  name: 'historicalAverageRates',
  url: '/api/rates/v3/rateInventory/average',
  setListPayload : ({ items = [], count = 0, error = undefined, errorMessage = '', query = {}, scope = JSON.stringify(query), ...payload } = {}) => ({ ...payload, [scope]: { items, count, error, errorMessage, isFetching: false, ...payload}, items, count, error, errorMessage, isFetching: false,}),
});

const { reducer: $chargeTypesList, actions: chargeTypesList, epics: chargeTypesListEpics } = listDux({
  name: 'ratesV3.$chargeTypes',
  url: '/api/rates/v3/chargetype/',
});

export const actions = { list, chargeTypesList, historicalAverageRates };
export const epics = [...listEpics, ...chargeTypesListEpics, ...historicalAverageRatesEpics];
export default combineReducers({ $list, $chargeTypesList, $historicalAverageRates });