import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';

const { reducer: $exportGeneralManifests, actions: exportGeneralManifests, epics: exportGeneralManifestsEpics } = culdDux({
  name: 'exportGeneralManifests',
  url: '/api/egm/manifests',
});

export const actions = { exportGeneralManifests};
export const epics = [ ...exportGeneralManifestsEpics, ];
export default combineReducers({ $exportGeneralManifests, });
