import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';


const { reducer: $comments, actions: comments, epics: commentEpics } = culdDux({
  name: 'comments',
  url: '/api/rfq/comments',
  switchRequests: false,
});



export const actions = { ...comments,};
export const epics = [...commentEpics, ];
export default combineReducers({ $comments,});