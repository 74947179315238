const routes = [
  {
    equals: '/dashboard',
    app: 'dashboard',
    isProtected: true,
  },
  {
    startsWith: '/manifests',
    app: 'manifests',
    isProtected: true,
  },
  {
    startsWith: '/login',
    name: 'loginRoutingApp',
    app: 'login',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/signup', '/update-password'],
    app: 'authV2',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/forgot-password',
    app: 'changePassword',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/change-password',
    app: 'changePassword',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/components',
    app: 'componentsDocumentation',
    hideHeader: false,
    hideFooter: false,
    hideSideNav: false,
  },
  {
    startsWith: '/setup',
    name: 'setup',
    app: 'auth',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/bookings',
    app: 'bookingsV2',
    isProtected: true,
  },
  {
    startsWith: '/chas',
    app: 'chas',
    isProtected: true,
  },
  {
    startsWith: '/contracts',
    app: 'contracts',
    isProtected: true,
  },
  {
    startsWith: '/customers',
    app: 'customers',
    isProtected: true,
  },
  {
    startsWith: '/account',
    app: 'customers',
    isProtected: true,
  },
  {
    startsWith: ['/enquiries', '/enquiriesV2'],
    app: 'enquiriesV2',
    isProtected: true,
  },
  {
    startsWith: ['/enquiries/printable-quote', '/enquiriesV2/printable-quote'],
    app: 'enquiriesV2',
    isProtected: true,
    hideSideNav: true,
    hideFooter: true,
    hideHeader: true,
  },
  {
    startsWith: ['/enquiries/view-quote', '/enquiriesV2/view-quote'],
    app: 'enquiriesV2',
    isProtected: false,
    hideSideNav: true,
    hideFooter: true,
    hideHeader: true,
  },
  {
    startsWith: '/invoices',
    app: 'invoices',
    isProtected: true,
  },
  {
    startsWith: '/messages',
    app: 'messages',
    isProtected: true,
  },
  {
    startsWith: '/faqs',
    app: 'pages',
    isProtected: true,
  },
  {
    startsWith: '/contact',
    app: 'pages',
    isProtected: true,
  },
  {
    startsWith: '/promo',
    app: 'promo',
    isProtected: true,
  },
  {
    startsWith: '/providers',
    app: 'providers',
    isProtected: true,
  },
  {
    startsWith: '/qbrates',
    app: 'qbRates',
    isProtected: true,
  },
  {
    startsWith: '/quotes',
    app: 'quotes',
    isProtected: true,
  },
  {
    startsWith: '/rates',
    exclude: { startsWith: '/rates-v2' },
    app: 'ratesV3',
    isProtected: true,
  },
  {
    startsWith: '/rates-v2',
    app: 'rates',
    isProtected: true,
  },
  {
    startsWith: '/reminders',
    app: 'reminders',
    isProtected: true,
  },
  {
    startsWith: '/reports',
    app: 'reports',
    isProtected: true,
  },
  {
    startsWith: '/products',
    app: 'products',
    isProtected: true,
  },
  {
    startsWith: '/ordersV2',
    app: 'ordersV2',
    isProtected: true,
  },
  {
    startsWith: '/ordersV2/acknowledge',
    app: 'ordersV2',
    name: 'ordersV2Acknowledgement',
    isProtected: false,
    hideSideNav: true
  },
  {
    startsWith: '/order-rfqs',
    app: 'orderRFQ',
    isProtected: true,
  },
  {
    startsWith: '/insights/v2',
    app: 'reportsV2',
    isProtected: true,
  },
  {
    startsWith: '/emission',
    app: 'emissionDashboard',
    isProtected: true,
  },
  {
    startsWith: '/shipwaves-tracking',
    app: 'oceanio',
    isProtected: true,
  },
  {
    startsWith: '/regularize',
    app: 'regularization',
    isProtected: false,
  },
  {
    startsWith: '/shipment-planner',
    app: 'shipmentPlanner',
    isProtected: true,
  },
  {
    startsWith: '/get-quote',
    app: 'shipmentPlanner',
    name: 'getQuote',
    hideHeader: true,
    hideSideNav: true,
    hideFooter: true,
  },
  {
    equals: '/icici',
    app: 'shipmentPlanner',
    name: 'schedulesICICI',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    equals: '/yes-connect',
    app: 'shipmentPlanner',
    name: 'yesConnectInternal',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    equals: '/hdfc',
    app: 'shipmentPlanner',
    name: 'hdfcInternal',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    equals: '/axis',
    app: 'shipmentPlanner',
    name: 'axisInternal',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    equals: '/m-junction',
    app: 'shipmentPlanner',
    name: 'mJunctionInternal',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    equals: '/fedex',
    app: 'shipmentPlanner',
    name: 'fedexInternal',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/schedules',
    exclude: {startsWith: '/schedules-v2'},
    app: 'schedules',
    isProtected: true,
  },
  {
    startsWith: '/schedules-v2',
    app: 'schedulesV2',
    isProtected: true,
  },
  {
    startsWith: '/rfq',
    exclude: { startsWith: '/rfq-v2' },
    app: 'rfqV2',
    isProtected: true,
  },
  {
    startsWith: '/rfq-v2',
    app: 'rfqV2',
    isProtected: true,
  },
  {
    startsWith: ['/rfq/print-quote'],
    app: 'rfqV2',
    isProtected: true,
    hideSideNav: true,
    hideFooter: true,
    hideHeader: true,
  },
  {
    equals: '/',
    app: 'shipments',
    isProtected: true,
  },
  {
    startsWith: '/shipments',
    app: 'shipments',
    isProtected: true,
  },
  {
    startsWith: '/containers',
    app: 'shipments',
    isProtected: true,
  },
  {
    startsWith: '/container-inventory',
    app: 'containerInventory',
    isProtected: true,
  },
  {
    startsWith: '/container-shipment',
    app: 'containerShipment',
    isProtected: true,
  },
  {
    startsWith: '/vendor-payments',
    app: 'shipments',
    isProtected: true,
  },
  {
    startsWith: '/payments',
    app: 'invoices',
    isProtected: true,
  },
  {
    startsWith: '/admin',
    app: 'admin',
    isProtected: true,
  },
  {
    startsWith: '/shippers',
    app: 'shippers',
    isProtected: true,
  },
  {
    startsWith: '/staffs',
    app: 'staffs',
    isProtected: true,
  },
  {
    startsWith: '/track-vessel',
    app: 'trackings',
    isProtected: true,
  },
  {
    startsWith: '/subscription-logs',
    app: 'trackings',
    isProtected: true,
  },
  {
    startsWith: '/trackings',
    exclude: { startsWith: '/trackings/v2' },
    app: 'trackings',
    isProtected: true,
  },
  {
    startsWith: '/trackings/v2',
    app: 'trackingsV2',
    isProtected: true,
  },
  {
    startsWith: '/global-shipment-radar',
    app: 'trackings',
    isProtected: true,
  },
  {
    startsWith: '/tracking/',
    name: 'sharedTracking',
    app: 'trackings',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/ddt',
    app: 'trackings',
    isProtected: true,
  },
  {
    startsWith: '/me',
    app: 'users',
    isProtected: true,
  },
  {
    startsWith: '/user-actions',
    app: 'users',
    isProtected: true,
  },
  {
    startsWith: '/enterprises',
    app: 'enterprises',
  },
  {
    startsWith: '/documents',
    app: 'documents',
    isProtected: true,
  },
  {
    startsWith: '/kyc',
    app: 'kyc',
    isProtected: true,
  },
  {
    startsWith: '/pay',
    app: 'pay',
    isProtected: true,
    hideHeader: true,
    hideSideNav: true,
    hideFooter: true,
    hideNotification: true,
  },
  {
    startsWith: '/icici-leads',
    app: 'iciciLeads',
    hideHeader: false,
    hideFooter: false,
    hideSideNav: false,
    isProtected: true,
  },
  {
    startsWith: '/orders',
    exclude: { startsWith: '/ordersV2' },
    app: 'orders',
    isProtected: true,
  },
  {
    startsWith: '/requisitions',
    app: 'requisitions',
    isProtected: true,
  },
  {
    startsWith: '/order-rfqs',
    app: 'orderRFQ',
    isProtected: true
  },
  {
    startsWith: '/network',
    app: 'network',
    isProtected: true,
  },
  {
    startsWith: '/configurations',
    app: 'configurations',
    isProtected: true,
  },
  {
    startsWith: '/dnd',
    app: 'demurrageAndDetention',
    isProtected: true,
  },
  {
    startsWith: '/egm',
    app: 'exportGeneralManifests',
    isProtected: true,
  },
  {
    startsWith: '/temp',
    app: 'temp',
  }
];

export default routes;
