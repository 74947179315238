import { ofType } from 'redux-observable';
import { first, map, withLatestFrom, } from 'rxjs/operators';

const defaultSideNavView = {
  size: 'collapsed',
  currentTheme: 'white',
},
  defaultSideNavDashboardReports = [{key:'Global Shipment Radar', value: true}, {key: 'Watch Tower', value: true}, {key: 'Shipment', value: true},{key: 'Multimodal', value: true}],
  defaultSideNavDashboardAnalytics = [{key: 'Monthly Shipments/TEUS', value: true},{key: 'Route Transit Time Consistency', value: true},{key: 'Performance By Origin', value: true},{key: 'Performance By Destination', value: true},{key: 'Avg Turnaround Time By Destination', value: true},{key: 'On Time Performance By Carrier', value: true}, {key: 'Active Shipments In Transit', value: true}, {key: 'Shipments Approaching Transshipment', value: true}, {key: 'Shipments Approaching Destination By POD', value: true}, {key: 'Transshipment Ageing By POD', value: true}]
export const actions = {
  setSideNav: 'setting.setSideNav',
  getSideNavView: 'setting.getSideNavView',
  setSideNavView: 'setting.setSideNavView',
  setSideNavDashboardReports: 'setting.setSideNavDashboardReports',
  getSideNavDashboardReports: 'setting.getSideNavDashboardReports',
  setSideNavDashboardAnalytics: 'setting.setSideNavDashboardAnalytics',
  getSideNavDashboardAnalytics: 'setting.getSideNavDashboardAnalytics',
}
export default function reducer(state = {
  slideSideNav: false,
  sideNavView: undefined,
  sideNavDashboardReports: undefined,
  sideNavDashboardAnalytics: undefined,
}, { type, payload = {} }) {
  switch (type) {
    case actions.setSideNav:
      return {
        ...state,
        ...payload
      }
    case actions.setSideNavView:
      return {
        ...state,
        ...payload
      }
    case actions.getSideNavView:
      return {
        ...state,
        ...payload,
      }
    case actions.getSideNavDashboardReports:
      return {
        ...state,
        ...payload,
      }
    case actions.setSideNavDashboardReports:
      return {
        ...state,
        ...payload
      }
    case actions.setSideNavDashboardAnalytics:
      return {
        ...state,
        ...payload
      }
    case actions.getSideNavDashboardAnalytics:
      return {
        ...state,
        ...payload,
      }
    default:
      return state;
  }
}
export const setSideNav = (value) => ({
  type: actions.setSideNav,
  payload: {
    slideSideNav: value,
  }
});
export const setSideNavView = (sideNavView) => ({
  type: actions.setSideNavView,
  payload: { sideNavView },
});
export const setSideNavDashboardReports = (sideNavDashboardReports) => ({
  type: actions.setSideNavDashboardReports,
  payload: { sideNavDashboardReports },
});
export const setSideNavDashboardAnalytics = (sideNavDashboardAnalytics) => ({
  type: actions.setSideNavDashboardAnalytics,
  payload: { sideNavDashboardAnalytics },
});
export const getSideNavView = (sideNavView = JSON.parse(localStorage.getItem('settings.sideNavView'))) => ({
  type: actions.getSideNavView,
  payload: {
    ...(sideNavView != null) ? { sideNavView } : {},
  },
});
export const getSideNavDashboardReports = (sideNavDashboardReports = JSON.parse(localStorage.getItem('settings.sideNavDashboardReports'))) => ({
  type: actions.getSideNavDashboardReports,
  payload: {
    ...(sideNavDashboardReports != null) ? { sideNavDashboardReports } : {},
  },
});
export const getSideNavDashboardAnalytics = (sideNavDashboardAnalytics = JSON.parse(localStorage.getItem('settings.sideNavDashboardAnalytics'))) => ({
  type: actions.getSideNavDashboardAnalytics,
  payload: {
    ...(sideNavDashboardAnalytics != null) ? { sideNavDashboardAnalytics } : {},
  },
});

export const setSideNavViewEpic = (action$, state$) => action$.pipe(
  ofType(actions.setSideNavView),
  withLatestFrom(state$),
  map(([{ payload: { sideNavView } = {} } = {},]) => {
    localStorage.setItem('settings.sideNavView', JSON.stringify(sideNavView));
    return getSideNavView();
  }),
);

export const getSideNavViewEpic = (action$, state$) => action$.pipe(
  ofType(actions.getSideNavView),
  first(),
  withLatestFrom(state$),
  map(([{ payload: { sideNavView } }]) => {
    if (sideNavView)
      return getSideNavView(sideNavView);
    const storedSideNavView = JSON.parse(localStorage.getItem('settings.sideNavView'));
    if (!storedSideNavView)
      localStorage.setItem('settings.sideNavView', JSON.stringify(defaultSideNavView));
    return getSideNavView();
  })
);

export const setSideNavDashboardReportsEpic = (action$, state$) => action$.pipe(
  ofType(actions.setSideNavDashboardReports),
  withLatestFrom(state$),
  map(([{ payload: { sideNavDashboardReports } = {} } = {},]) => {
    localStorage.setItem('settings.sideNavDashboardReports', JSON.stringify(sideNavDashboardReports));
    return getSideNavDashboardReports();
  }),
);

export const setSideNavDashboardAnalyticsEpic = (action$, state$) => action$.pipe(
  ofType(actions.setSideNavDashboardAnalytics),
  withLatestFrom(state$),
  map(([{ payload: { sideNavDashboardAnalytics } = {} } = {},]) => {
    localStorage.setItem('settings.sideNavDashboardAnalytics', JSON.stringify(sideNavDashboardAnalytics));
    return getSideNavDashboardAnalytics();
  }),
);

export const getSideNavDashboardReportsEpic = (action$, state$) => action$.pipe(
  ofType(actions.getSideNavDashboardReports),
  first(),
  withLatestFrom(state$),
  map(([{ payload: { sideNavDashboardReports } }]) => { 
    if (sideNavDashboardReports)
      return getSideNavDashboardReports(sideNavDashboardReports);
    const storedSideNavDashboardReports = JSON.parse(localStorage.getItem('settings.sideNavDashboardReports'));
    if (!storedSideNavDashboardReports)
      localStorage.setItem('settings.sideNavDashboardReports', JSON.stringify(defaultSideNavDashboardReports));
    return getSideNavDashboardReports();
  })
);


export const getSideNavDashboardAnalyticsEpic = (action$, state$) => action$.pipe(
  ofType(actions.getSideNavDashboardAnalytics),
  first(),
  withLatestFrom(state$),
  map(([{ payload: { sideNavDashboardAnalytics } }]) => {
    if (sideNavDashboardAnalytics)
      return getSideNavDashboardAnalytics(sideNavDashboardAnalytics);
    const storedSideNavDashboardAnalytics = JSON.parse(localStorage.getItem('settings.sideNavDashboardAnalytics'));
    if (!storedSideNavDashboardAnalytics)
      localStorage.setItem('settings.sideNavDashboardAnalytics', JSON.stringify(defaultSideNavDashboardAnalytics));
    return getSideNavDashboardAnalytics();
  })
);