import { combineReducers } from 'redux';

import { ajaxEpic } from './lib/epic';
import dux from './lib/dux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';

const { reducer: $approvalUserConfigurations, actions: approvalUserConfigurations, epics: approvalUserConfifurationsEpics } = culdDux({
  name: 'approvalUserConfigurationsConfig',
  url: '/api/configurations/approval-user-configurations',
});

const { reducer: $createConfiguration, actions: createConfiguration, epics: createConfigurationEpics } = createDux({
  name: 'createConfiguration',
  url: '/api/configurations/approval-user-configurations',
});

const { reducer: $updateConfiguration, actions: updateConfiguration, epics: updateConfigurationEpics } = updateDux({
  name: 'updateConfiguration',
  url: '/api/configurations/approval-user-configurations',
});

const { reducer: $configurationDetails, actions: configurationDetails, epics: configurationDetailsEpics } = detailsDux({
  name: 'configurationDetails',
  url: '/api/configurations/approval-user-configurations',
  setDetailsFromListPayload: () => ({})
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'approvalUserConfigurationsList',
  url: '/api/configurations/approval-user-configurations'
});

const {reducer: $listRulesCriteria, actions: listRulesCriteria, epics: listRulesCriteriaEpic} = listDux({
  name: 'rulesCriteria',
  url: '/api/configurations/rulesCriteria',
});
 
export const actions = { approvalUserConfigurations, createConfiguration, list, listRulesCriteria, configurationDetails, updateConfiguration };
export const epics = [ ...approvalUserConfifurationsEpics, ...createConfigurationEpics, ...listEpics, ...listRulesCriteriaEpic, ...configurationDetailsEpics, ...updateConfigurationEpics ];
export default combineReducers({ $approvalUserConfigurations, $createConfiguration, $list, $listRulesCriteria, $configurationDetails, $updateConfiguration });
